import { reactive, watch, ref } from 'vue'
import moment from 'moment'
import { PayoutBatchTableData,BUSearchParams } from "@/views/Payment/BU/types"
import * as API from "@/API/payment"
import { useStore } from 'vuex';
import {
    getPayoutCodeTable,
    getRoundListByDate
} from "@/API/payment";
import {ColumnProps} from "ant-design-vue/es/table/interface";
import {AttachmentData} from "@/views/Payment/FlRt/types";
import {message, Modal} from "ant-design-vue";
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";
import { downloadFromStream } from '@/utils/payment/downloadFile'
import downloadFile from "@/utils/payment/downloadFile";
import useWait from '@/utils/payment/useWait'
import usePageSpinning from '@/utils/usePageSpinning'

type Key = ColumnProps['key'];
/*type SearchParams = {
    years: string;
    payoutRound: string;
    BU: string;
    programCode: string;
    programStatus: string;
}*/

const useDealerTab = () => {
    // 查询区数据：包括 payoutYears|payoutMonth|payoutRound|payoutCode|BU|programCode|payoutStatus
    const searchParams = reactive<BUSearchParams>({
        payoutYears: moment(),
        payoutMonth: null,
        payoutRound:[],
        payoutCode: [],
        BU:['AMG'],
        programCode:'',
        payoutStatus:[]
    });
    const state = reactive({
        selectedRowKeys: [], // Check here to configure the default column
        selectedRows: [],
        payoutYear: '9999',
        payoutMonth: undefined,
        payoutRound: undefined as string | undefined,
        payoutCode: [],
        BU:[],
        programCode:'',
        payoutStatus: [],
        entity: ''
    });

    const wait = useWait()

    // 上传的附件列表
    const attachmentData = ref<AttachmentData[]>([])
    const isUploadVisible = ref<boolean>(false)
    //region [监听查询区“年”]

    const pageSpinning = usePageSpinning()

    watch(() => searchParams.payoutYears, (value) => {
        if (value) {
            searchParams.payoutMonth?.year(value.year())
            const myparams = {
                type: 'DEALER',
                year: searchParams.payoutYears.format('YYYY'),
                month: '0'
            }
            API.getRoundListByDate({params:myparams}).then((res) => {
                state.payoutRound = undefined
                searchParams.payoutRound = res
                if (searchParams.payoutRound.length) {
                  state.payoutRound = searchParams.payoutRound[0]
                } else {
                  state.payoutRound = undefined
                  wait.toRun?.()
                }
            })
        }
    })
    //region [侦听查询区“payout Round”，获取payoutCode]
    watch<[() => string | undefined, () => string]>([()=> state.payoutRound, () => state.entity ],([payoutRound, entity]) => {
        if (payoutRound && entity) {
            API.getPayoutCodeListByPayoutRoundAndEntity(payoutRound, entity).then((res) => {
                searchParams.payoutCode = res.length && res[0] === '' ? [] : res
                state.payoutCode = !res.length || res[0] === '' ? [] :[res[0]] as any
                wait.toRun?.()
            })
        } else {
            searchParams.payoutCode = []
            state.payoutCode = []
            if (payoutRound) {
              wait.toRun?.()
            }
        }
    })
    //#endregion

    //#region [定义rowSelectionD和rowSelectionAfc]
    const rowSelectionPayoutCode = reactive<{ selectedRowKeys: Key[]; selectedRowData: PayoutBatchTableData[]; onChange: Function;columnWidth: string}>({
        onChange: (selectedRowKeys: Key[], selectedRows: PayoutBatchTableData[]) => {
            rowSelectionPayoutCode.selectedRowKeys = selectedRowKeys;
            rowSelectionPayoutCode.selectedRowData = selectedRows;
        },
        selectedRowKeys: [],
        selectedRowData: [],
        columnWidth: '30px'
    })
    const rowSelectionPending = reactive<{ selectedRowKeys: Key[]; selectedRowData: PayoutBatchTableData[]; onChange: Function;columnWidth: string}>({
        onChange: (selectedRowKeys: Key[], selectedRows: PayoutBatchTableData[]) => {
            rowSelectionPending.selectedRowKeys = selectedRowKeys;
            rowSelectionPending.selectedRowData = selectedRows;
        },
        selectedRowKeys: ref<any>([]),
        selectedRowData: ref<any>([]),
        columnWidth: '30px'
    })
//#endregion
    const columnsPayoutBatch = [
        { title: "Payout Round", className:"column-no-wrap", dataIndex: "payoutRound",slots: { customRender: 'payoutRound' },width: 190},
        { title: "Payout Code", className:"column-no-wrap", dataIndex: "payoutCode",slots: { customRender: 'payoutCode' }, key: "payoutCode", width: 220},
        { title: "Dealer Number", dataIndex: "dealerNumber",slots: { customRender: 'dealerNumber' }, key: "dealerNumber", width: 80, align: 'right'},
        { title: "Payout Amount w/o VAT", dataIndex: "payoutAmountWOVat",customRender: useThousandsAndTwoDecimal(),  width: 160, align: 'right'},
        { title: "Payout Amount w VAT", dataIndex: "payoutAmountWVat",customRender: useThousandsAndTwoDecimal(),  width: 180, align: 'right'},
        { title: "Lastest Update Time", dataIndex: "latestUpdateTime", width: 160, key: "latestUpdateTime", align: 'center'},
        { title: 'Status', dataIndex: "status", width: 100, align: 'center'},
        { title: "Operation", key: "operation", fixed: 'right',  align: 'left', width: 150, slots: { customRender: "action"}}

    ]
    const columnsPendingCreateBatch = [
        { title: "Payout Round", dataIndex: "payoutRound", className:"column-no-wrap", slots: { customRender: 'payoutRound' }, width: 130},
        { title: "Program Code", className:"column-no-wrap", dataIndex: "programCode", slots: { customRender: 'payoutCode' },width: 100},
        { title: "Program Name", dataIndex: "programName",slots: { customRender: 'programNo' }, width: 100, align: 'right' },
        { title: "Program BU", dataIndex: "programBu",slots: { customRender: 'programBu' },width: 80,align: 'right'},
        { title: "Dealer Number", dataIndex: "dealerNumber",slots: { customRender: 'dealerNumber' },  width: 100, align: 'right'},
        { title: 'Payout Amount w/o VAT', dataIndex: "payoutAmountWOVat", customRender: useThousandsAndTwoDecimal(),width: 120, align: 'right'},
        { title: "Payout Amount w VAT", dataIndex: "payoutAmountWVat", customRender: useThousandsAndTwoDecimal(), width: 120, key: "retailDate", align: 'right'}
    ]

    const tableDataPayoutBatch = ref<PayoutBatchTableData[]>()
    const tableDataPendingCreateBatch = ref<PayoutBatchTableData[]>()

    const store = useStore()
    //region [获取查询区初始化 “年”和“月”]
    const getSearchParamResult = ()=> {
        API.getSearchResultForDealerPayoutOverview().then((res) => {
            console.log('查询区后台返回结果=====','0'.concat(res.data.month.toString()))
            if (res.code == '0'){
                const month = res.data.month.toString().length==2 ? res.data.month.toString():'0'.concat(res.data.month.toString())
                searchParams.payoutYears = moment(res.data.year.toString())
                searchParams.payoutStatus = res.data.dealerStatusList
                //state.payoutStatus = [''] as any
            }
            //tableDataPayoutBatch.value = res.content
        })
    }

    const doSearchPayoutCodeTable = () => {
        const paramsPayoutCodeList = {
            year: searchParams.payoutYears.format('YYYY'),
            month: 0,
            payoutRound:state.payoutRound,
            payoutCodeList: state.payoutCode,
            programCode: state.programCode,
            programBuList: state.BU,
            type: 'DEALER',
            statusList: state.payoutStatus,
            entity: state.entity
        }

        return API.getPayoutCodeTable(paramsPayoutCodeList).then((res) => {
            tableDataPayoutBatch.value = res.data
        })
    }

    const doSearchPendingPayoutCodeTable = () => {
        const paramsPendingList = {
            year: searchParams.payoutYears.format('YYYY'),
            month: 0,
            payoutRound:state.payoutRound,
            programBuList: state.BU,
            statusList: state.payoutStatus,
            role: '',
            programCode: state.programCode,
            entity: state.entity
        }

        return API.getPendingPayoutCodeTable({...paramsPendingList}).then((res) => {
            if (res.data.length >0){
                res.data.forEach((item: any) => {
                    item.payoutAmountWOVat = parseFloat(item.payoutAmountWOVat)
                    item.payoutAmountWVat = parseFloat(item.payoutAmountWVat)
                })
            }
            tableDataPendingCreateBatch.value = res.data
        })
    }

    //#endregion
    //region [Search 按钮]
    const doSearch = () => {
      pageSpinning(wait.then(() => Promise.all([doSearchPayoutCodeTable(), doSearchPendingPayoutCodeTable()])))
    }
    //#endregion

    const code = ref<string>('')
    const round = ref<string>('')
    const payoutId = ref<string>('')
    const statusToUpload = ref<string>('')
    const uploadHandle = (record: any) => {
        console.log('record======',record)
        code.value = record.payoutCode
        round.value = record.payoutRound
        payoutId.value = record.id
        statusToUpload.value = record.status
        API.getUploadFileData({params:{
                paymentId:record.id
            }}).then((res)=>{
            console.log('返回附件列表：',res)
            attachmentData.value = res
        });
        isUploadVisible.value = true
        /*API.getUploadFileData({ params }).then((res) => {
          attachmentData.value = [];
          res.forEach((item, index) => {
            attachmentData.value.push({
              id: index,
              comments: item.comments,
              replyAt: item.replyAt,
            });
          });
          showFailFlag.value = true;
        });*/

    }
    const getUploadFileData = (id: string) =>{
        return API.getUploadFileData({params:{
                paymentId:id
            }}).then((res)=>{
            console.log('返回附件列表：',res)
            attachmentData.value = res
            return attachmentData.value = res
        });
    }
    const deleteModalVisible = ref<boolean>(false)
    const withdrawModalVisible = ref<boolean>(false)
    const submitModalVisible = ref<boolean>(false)
    const withdrawInstructions = ref<string | null>(null)
    const deleteInstructions = ref<string | null>(null)
    const onDeleteConfirm = ()=>{
        if (!deleteInstructions.value){
            message.error('please input delete reasons!')
            return;
        }
        if (rowSelectionPayoutCode.selectedRowKeys.length == 1){
            const delParams = {
                "deleteComment": deleteInstructions.value,
                "id": rowSelectionPayoutCode.selectedRowKeys[0]
            }
            API.batchDeletePayoutCodes(delParams).then((res)=>{
                if (res.code == '0'){
                    deleteModalVisible.value = false
                    rowSelectionPayoutCode.selectedRowData = []
                    rowSelectionPayoutCode.selectedRowKeys = []
                    deleteInstructions.value = ""
                    Modal.info({
                        title: 'Tips',
                        content: 'Successfully Delete！'//res.data.message
                    })
                    doSearchPayoutCodeTable()
                }
                else {
                    Modal.error({
                        title: 'Tips',
                        content: res.message//res.data.message
                    })
                }
            })
        }
        if (rowSelectionPending.selectedRowData.length == 1){
            const delParamss = {
                "deleteComment": deleteInstructions.value,
                "id": rowSelectionPending.selectedRowKeys[0]
            }
            API.batchDeletePrograms(delParamss).then((res)=>{
                if (res.code == '0'){
                    deleteModalVisible.value = false
                    rowSelectionPending.selectedRowKeys = []
                    rowSelectionPending.selectedRowData = []
                    deleteInstructions.value = ""
                    Modal.info({
                        title: 'Tips',
                        content: 'Successfully Delete！'//res.data.message
                    })
                    doSearchPendingPayoutCodeTable()
                }
                else {
                    Modal.error({
                        title: 'Tips',
                        content: res.message//res.data.message
                    })
                }
            })
        }
    }
    const onWithdrawConfirm = () => {
        if (!withdrawInstructions.value) {
            message.warning('Reasons for withdrawal are required');
            return false
        }
        const data = rowSelectionPayoutCode.selectedRowData.map((item) => {
            return {
                id:item.id,
                withDrawComment : withdrawInstructions.value
            }
        })
        API.batchWithDrawPrograms(data[0]).then((res: any) => {
            if (res.code == '0'){
                withdrawModalVisible.value = false
                Modal.info({
                    title: 'Tips',
                    content: 'Successfully Withdraw！'//res.data.message
                })
                doSearch()
                rowSelectionPayoutCode.selectedRowData = []
                rowSelectionPayoutCode.selectedRowKeys = []
                withdrawInstructions.value = ""
            }
            else {
                Modal.error({
                    title: 'Tips',
                    content: res.message//res.data.message
                })
            }
        })
    }
    const onSubmitConfirm = () => {
        const submitParams = {
            paymentOverviewId: rowSelectionPayoutCode.selectedRowKeys
        }
        API.batchSubmitPrograms(submitParams).then((res) => {
            if (res.code !== "50077" && res.code !== "50079") {
                Modal.info({
                    title: 'Tips',
                    content: 'Successfully Submit！'//res.data.message
                })
                submitModalVisible.value = false
                doSearch()
                rowSelectionPayoutCode.selectedRowData = []
                rowSelectionPayoutCode.selectedRowKeys = []
            } else {
                submitModalVisible.value = false
            }
            
        })
    }
    const onDownloadConfirm = () =>{
        const payoutCodes = rowSelectionPayoutCode.selectedRowData.map((item) => item.payoutCode)
        // API.downloadPayoutCodeFile(payoutCodes).then((res: unknown) => {
        //   downloadFromStream(res)
        // })
        const _payoutCodes = payoutCodes.join(',')
        const params = {
            url: `/ptapi/program/dealer/excelByCode?payoutCode=${_payoutCodes}`,
            method: "post"
          };
          downloadFile(params);
    }
    return {
        state,
        submitModalVisible,
        withdrawModalVisible,
        withdrawInstructions,
        deleteModalVisible,
        deleteInstructions,
        payoutId,
        code,
        round,
        statusToUpload,
        isUploadVisible,
        uploadHandle,
        searchParams,
        rowSelectionPayoutCode,
        rowSelectionPending,
        columnsPayoutBatch,
        columnsPendingCreateBatch,
        tableDataPayoutBatch,
        tableDataPendingCreateBatch,
        getSearchParamResult,
        getRoundListByDate,
        doSearch,
        onDownloadConfirm,
        onDeleteConfirm,
        onWithdrawConfirm,
        onSubmitConfirm,
        attachmentData,
        getPayoutCodeTable
    }
}
export default useDealerTab